//------------------------------------------------------
// HOME
//------------------------------------------------------
// box-modules //
section[id^="home-"] {
    position: relative;
    overflow: hidden;
}
#home-news {
    @include mq-sp {
        padding: 8em 0 7em 0;
        z-index: 1;
        margin-top: -4em;
        &::before {
            @include bcg-base;
            height: 55em;
        }
    };
    @include mq-pc {
        padding: 320px 0 300px 0;
        z-index: 1;
        margin-top: -240px;
        &::before {
            @include bcg-base;
            height: 870px;
        }
    };
}
#home-company {
    @include mq-sp {
        z-index: 2;
        &::before {
            @include bcg-base;
        }
    };
    @include mq-pc {
        z-index: 2;
        margin-top: -250px;
        &::before {
            @include bcg-base;
        }
    };
}
#home-product {
    @include mq-sp {
        z-index: 3;
        padding: 6em 0 3em 0;
        margin-top: -4em;
        &::before {
            @include bcg-base;
            height: 36em;
        }
    };
    @include mq-pc {
        z-index: 3;
        padding-bottom: 60px;
        margin-top: -100px;
        &::before {
            @include bcg-base;
            height: 75vw;
        }
    };
}
#home-solution {
    @include mq-sp {
        z-index: 4;
        padding-bottom: 4em;
        &::before {
            @include bcg-base;
            top: 6em;
            height: 31em;
        }
    };
    @include mq-pc {
        z-index: 4;
        padding-bottom: 140px;
        &::before {
            @include bcg-base;
            top: 50px;
            height: 600px;
        }
    };
}

// headeing //
.home-heading {
    text-align: center;
    @include mq-sp {
        margin-bottom: 1.5em;
        font-size: $sp-text-S;
        span {
            display: block;
            &.jp {
                font-weight: normal;
                color: $accent-color-bu;
            }
            &.en {
                font-family: $font-os;
                font-weight: 700;
                margin-top: -0.5em;
                font-size: 7.5vw;
            }
        }
    };
    @include mq-pc {
        margin-bottom: 35px;
        span {
            display: block;
            &.jp {
                font-weight: normal;
                color: $accent-color-bu;
            }
            &.en {
                font-family: $font-os;
                font-weight: 700;
                margin-top: -18px;
                font-size: 4rem;
            }
        }
    };
}

// kv //
.kv {
    position: relative;
    z-index: 2;
    .slider {
        width: 100%;
        margin: 0 auto;
        img {
        width: 100%;
        }
    }
    @include mq-sp {
        margin-top: 60px;
        padding-bottom: 40px;
        background-color: $base-color;
        h2 {
            visibility: visible;
            position: absolute;
            top: 45vw;
            left: 5vw;
            z-index: 100;
            width: 54vw;
            img {
                width: 100%;
                height: auto;
                filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
            }
        }
    };
    @include mq-pc {
        margin-top: 140px;
        h2 {
            visibility: visible;
            position: absolute;
            top: 5vw;
            left: 5vw;
            z-index: 100;
            width: 30vw;
            img {
                width: 100%;
                height: auto;
                filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
            }
        }
    };
}

// movie //
.home-movie {
    @include mq-sp {
        position: relative;
        z-index: 2;
        background-color: $base-color;
        video {
            display: block;
            width: 320px;
            margin: 0 auto;
        }
    };
    @include mq-pc {
        position: absolute;
        right: 20px;
        bottom: 30px;
        z-index: 3;
        width: 340px;
        padding: 10px 0;
        background-color: rgba(255, 255, 255, 0.3);
        video {
            display: block;
            margin: 0 auto;
        }
        .btn-more-S.btn-brand {
            margin-top: 10px;
            a {
                width: 320px;
            }
        }
    };
}

// news release //
.post-index-wrap {
    display: flex;
    justify-content: space-between;
    @include mq-sp {
        flex-wrap: wrap;
    };
}
.post-index {
    background-color: #fff;
    @include mq-sp {
        width: 47.5%;
        margin-bottom: 5%;
        a {
            display: block;
            text-decoration: none;
        }
        figure {
            position: relative;
            .post-category {
                position: absolute;
                left: 0;
                bottom: 0;
                padding: 6px 1em;
                background-color: $accent-color-rd;
                line-height: 1.0;
                color: #fff;
                font-family: $font-os;
                font-weight: 600;
                font-size: $sp-text-S;
            }
        }
        .post-date {
            color: $accent-color-rd;
            font-weight: 600;
            margin: 4vw 4vw 0 4vw;
            font-size: $sp-text-S;
        }
        h2 {
            margin: 1vw 4vw 4vw 4vw;
            line-height: 1.8;
            font-weight: normal;
        }
    };
    @include mq-pc {
        width: calc((100% - 60px) / 4);
        a {
            display: block;
            text-decoration: none;
            &:hover {
                @include hover-action;
            }
        }
        figure {
            position: relative;
            .post-category {
                position: absolute;
                left: 0;
                bottom: 0;
                padding: 6px 1em;
                background-color: $accent-color-rd;
                line-height: 1.0;
                color: #fff;
                font-family: $font-os;
                font-weight: 600;
                font-size: 1.3rem;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        .post-date {
            color: $accent-color-rd;
            font-weight: 600;
            margin: 1em 1em 0 1em;
            margin: 20px 20px 0 20px;
            font-size: 1.4rem;
        }
        h2 {
            margin: 5px 20px 20px 20px;
            line-height: 1.6;
            font-weight: normal;
        }
    };
}

// company //
.home-company-pict {
    @include mq-pc {
        text-align: right;

        &.--garman{
          padding-top: 150px;
        }
    };
}
.home-company-text {
    @include mq-sp {
        width: 90%;
        margin: 0 auto;
    };
    @include mq-pc {
        @include center;
        width: 1040px;
        #home-company & {
            top: 50%;
        }
        h1, p {
            width: 380px;

            &.--garman{
              line-height: 1.6;
            }
        }
    };
}
.home-text {
	@include mq-sp {
		width: 90%;
        margin: 0 auto;
        text-align: left;
	};
}

// product //
.home-product-pict {
    display: flex;
    align-items: flex-start;
    img {
        width: calc(100% / 3);
        max-width: 100%;
        height: auto;
        &:nth-of-type(1) {
            margin-top: 6.9vw;
        }
        &:nth-of-type(2) {
            margin-top: 3.45vw;
        }
    }
    @include mq-sp {
        &.pict-footer {
            margin-top: 3em;
        }
    };
    @include mq-pc {
        &.pict-header {
            margin-bottom: 2em;
        }
        &.pict-footer {
            margin-top: 2em;
        }
    };
}

// solution //
.home-solution-container {
    @include mq-pc {
        position: relative;
        display: flex;
        margin-left: calc((100% - 1040px) / 2);
        .home-solution-text {
            margin: 55px 3em 0 0;
        }
    };
}
.home-solution-pict {
    display: table;
    @include mq-sp {
        width: 90%;
        margin: 0 auto;
        figure {
            display: table-cell;
            vertical-align: top;
            width: 50%;
            &:first-of-type {
                padding-top: 2em;
            }
        }
    };
    @include mq-pc {
        figure {
            display: table-cell;
            vertical-align: top;
            &:first-of-type {
                padding-top: 60px;
            }
        }
    };
}

// modaal //
@include mq-sp {
    .modaal-content-container {
        padding: 10% 0 !important;
        background: $base-color url('../images/home/bcg_modal.png') no-repeat center 13vw;
        background-size: 290%;
        .brand-heading {
            margin-bottom: 3em;
            text-align: center;
            h1 {
                font-size: $sp-text-S;
                font-weight: normal;
                color: $accent-color-bu;
            }
            h2 {
                margin-top: -0.5em;
                font-size: 6.875vw;
                font-family: $font-os;
                font-weight: 700;
                span {
                    @include heading-center;
                }
            }
        }
        h3 {
            margin-bottom: 1em;
            text-align: center;
            span {
                display: block;
                &.jp {
                    font-size: $sp-text-S;
                    font-weight: normal;
                    color: $accent-color-bu;
                }
                &.en {
                    margin-top: -0.5em;
                    font-size: 5.9375vw;
                    font-family: $font-os;
                    font-weight: 700;
                }
            }
        }
        h4 {
            margin-bottom: 0.5em;
            line-height: 1.6;
            font-size: $sp-text-M;
        }
        section + section {
            margin-top: 4em;
        }
        .brand-container {
            width: 90%;
            margin: 0 auto;
            figure {
                width: 80%;
                margin: 1em auto 0 auto;
            }
        }
    }
};
@include mq-pc {
    .modaal-container {
        max-width: 900px !important;
    }
    .modaal-content-container {
        padding: 60px 0 !important;
        background: $base-color url('../images/home/bcg_modal.png') no-repeat center 160px;
        .brand-heading {
            margin-bottom: 120px;
            text-align: center;
            h1 {
                font-size: 1.6rem;
                font-weight: normal;
                color: $accent-color-bu;
            }
            h2 {
                margin-top: -20px;
                font-size: 4.5rem;
                font-family: $font-os;
                font-weight: 700;
                span {
                    @include heading-center;
                }
            }
        }
        h3 {
            margin-bottom: 1em;
            text-align: center;
            span {
                display: block;
                &.jp {
                    font-size: 1.3rem;
                    font-weight: normal;
                    color: $accent-color-bu;
                }
                &.en {
                    margin-top: -16px;
                    font-size: 3.5rem;
                    font-family: $font-os;
                    font-weight: 700;
                }
            }
        }
        h4 {
            margin-bottom: 0.5em;
            line-height: 1.6;
            font-size: 1.8rem;
        }
        section + section {
            margin-top: 120px;
        }
        .brand-container {
            display: flex;
            align-items: center;
            width: 80%;
            margin: 0 auto;
            figure {
                margin-left: 40px;
            }
            &.brand-odd {
                flex-direction: row-reverse;
                figure {
                    margin: 0 40px 0 0;
                }
            }
        }
    }
};


//------------------------------------------------------
// news
//------------------------------------------------------
.news-container {
    & + .news-container {

        padding-top: 8rem;
        margin-top: 0;
    }
    .news-header {
        .heading-news {
            position: relative;
            line-height: 1.6;
            &::after {
                content: " ";
                position: absolute;
                display: block;
                border-bottom: solid 3px $accent-color-bu;
                bottom: -3px;
                width: 3em;
            }
            @include mq-sp {
                margin-bottom: 1.5em;
                padding-bottom: .5em;
                border-bottom: 3px solid #ddd;
                font-size: $sp-text-L;
            };
            @include mq-pc {
                margin: -10px 0 15px 0;
                padding-bottom: 15px;
                border-bottom: 3px solid #ddd;
                line-height: 1.6;
                font-size: 2.0rem;
            };
        }
        .news-date {
            margin-bottom: 20px;
            font-weight: bold;
            text-align: right;
        }
    }
    .news-body {
        p {
            margin-bottom: 1em;
        }
    }
}
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    margin-top: 6em;
    text-align: center;
    font-size: 1.4rem;
    a {
        display: block;
        margin: 0 5px;
        width: 2.5em;
        height: 2.5em;
        line-height: 2.5em;
        border: solid 2px #dadada;
        border-radius: 50% 50%;
        text-decoration: none;
        &.current {
            background-color: $main-color;
            border: solid 1px $main-color;
            color: #fff;
            font-weight: bold;
        }
    }
}

.news-data  {
    display: inline-block;
    background-color: $main-color;
    color:#fff;
    @include mq-sp {
        margin-bottom: 2em;
        padding: 0.3em 1em 0.2em 1em;
    };
    @include mq-pc {
        margin-bottom: 3em;
        padding: 4px 20px 3px 20px;
        font-size: 1.6rem;
    };
}



//------------------------------------------------------
// company
//------------------------------------------------------
.company-movie video {
    display: block;
    @include mq-sp {
        width: 280px;
        margin: 0 auto 3em auto;
    };
    @include mq-pc {
        margin: 0 auto 6em auto;
    };
}
.company-index {
    @include mq-sp {
        ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-left: 0;
            font-size: $sp-text-SS;
            li {
                width: 47%;
                padding: 1em 0;
                border-bottom: solid 1px $border-gy;
                &::before {
                    @include bullet-arrow;
                    color: $accent-color-bu;
                }
                a {
                    text-decoration: none;
                }
            }
        }
    };
    @include mq-pc {
        ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-left: 0;
            li {
                width: 47%;
                padding: 1em 0;
                border-bottom: solid 1px $border-gy;
                &::before {
                    @include bullet-arrow;
                    color: $accent-color-bu;
                }
                a {
                    text-decoration: none;
                }
            }
        }
    };
}

.dl-aboutus {
    dt {
        font-weight: bold;
    }
    dd {
        margin-bottom: 2em;
    }
}

.tbl-common.tbl-company {

    dt {
        float: left;
        width: 12em;
    }
    dd {
        margin-left: 8.5em;
    }
    @include mq-sp {
      width: 600px;
        th {
            text-align: center;
            white-space: nowrap;
        }
    };
    @include mq-pc {
        th {
            width: 25%;
            text-align: center;
        }
    };
}

.tbl-history {
    width: 100%;
    background-color: #fff;
    tr:nth-child(odd) {
        background-color: $base-color;
    }
    @include mq-sp {
        border-bottom: solid 1px $border-gy;
        th, td {
            display: block;
        }
        th {
            padding: 1em 1em 0 1em;
            border-top: solid 1px $border-gy;
            font-weight: bold;
        }
        td {
            padding: 0 1em 1em 1em;
        }
    };
    @include mq-pc {
        th, td {
            padding: 1em;
            border-top: solid 1px $border-gy;
            border-bottom: solid 1px $border-gy;
        }
        th {
            white-space: nowrap;
            font-weight: bold;
        }
    };
}

.tbl-common.tbl-warranty {
    margin-bottom: 1em;
    th, td {
        border-left: solid 1px $border-gy;
        vertical-align: middle;
        }
    @include mq-sp {
        font-size: $sp-text-S;
        th {
            white-space: nowrap;
            text-align: center;
        }
    };
    @include mq-pc {
        font-size: 1.3rem;
        th {
            white-space: nowrap;
            text-align: center;
        }
    };
}

.dl-warranty {
    margin-top: 2em;
    dt {
        font-weight: bold;
    }
    dd {
        margin-bottom: 1em;
    }
    @include mq-sp {
        font-size: $sp-text-S;
    };
    @include mq-pc {
        font-size: 1.3rem;
    };
}

.list-facility {
    @include mq-pc {
        display: flex;
        flex-wrap: wrap;
        li {
            width: 50%;
        }
    };
}

.network-map {
    padding: 0.5em;
    background-color: #fff;
    border: solid 1px $border-gy;
    figure {
        padding: 1em 0;
        text-align: center;
    }
    .network-index {
        padding: 1em 1.5em 0 1.5em;
        background-color: #f6f6f6;
    }
    @include mq-sp {
        margin-bottom: 4em;
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-left: 0;
            padding-bottom: 1em;
            list-style: none;
            font-size: $sp-text-S;
            li {
                width: 50%;
                margin-bottom: 0;
            }
            a {
                text-decoration: none;
            }
        }
    };
    @include mq-pc {
        margin-bottom: 6em;
        ul {
            display: flex;
            margin-left: 0;
            list-style: none;
            font-size: 1.4rem;
            li {
                margin-right: 1.5em;
            }
            a {
                text-decoration: none;
            }
        }
    };
}

.network-japan {
    color: $accent-color-rd;
}
.network-global {
    color: $accent-color-rd;
}
.network-agency {
    color: $accent-color-bu;
}
.network-japan-list li::first-letter,
h5[class*="office_japan"]::first-letter {
    margin-right: 2px;
    color: $accent-color-rd;
}
.network-global-list li::first-letter,
h6[class*="office_global"]::first-letter {
    margin-right: 2px;
    color: $accent-color-rd;
}
.network-agency-list li::first-letter,
h6[class*="agency"]::first-letter {
    margin-right: 2px;
    color: $accent-color-bu;
}

.office-address {
    dt {
        margin-top: 1em;
        font-weight: bold;
    }
    figure {
        border: solid 1px $border-gy;
        background-color: #fff;
        text-align: center;
        img {
            margin-bottom: 0;
        }
    }
    @include mq-sp {
        .heading-05:first-letter {
            margin-right: 0.5em;
        }
        figure {
            width: 100%;
            margin-top: 2em;
        }
    };
    @include mq-pc {
        .heading-05:first-letter {
            margin-right: 0.5em;
        }
        figure {
            width: 300px;
        }
    };
}

.g-map {
    margin-top: 1em;
    border: solid 3px #fff;
    iframe {
        vertical-align: middle !important;
        @include mq-sp {
            width: 100%;
            height: 300px;
        };
        @include mq-pc {
            width: 294px;
            height: 300px;
        };
    }
}



//------------------------------------------------------
// product
//------------------------------------------------------
.product-index-wrap {
    @include mq-sp {
        .product-index {
            width: 100%;
            padding: 1.5em;
            margin-bottom: 5%;
            border: solid 1px $border-gy;
            background-color: #fff;
            .heading-product {
                margin: 1em 0;
                text-align: center;
                font-size: $sp-text-M;
                line-height: 1.4;
            }
            figure {
                text-align: center;
            }
            .product-index-list {
                background-color: $base-color;
                padding: 1.5em;
                ul{
                    margin-top: 0.5em;
                }
            }
        }
    };
    @include mq-pc {
        .product-index {
            display: flex;
            padding: 2em;
            margin-bottom: 2em;
            border: solid 1px $border-gy;
            background-color: #fff;
            &.pro-id {
                display: block;
            }
            .product-index-co {
                display: flex;
            }
            .heading-product {
                margin-bottom: 1em;
                font-size: 1.8rem;
                line-height: 1.4;
            }
            figure {
                margin-right: 2em;
                text-align: center;
            }
            .product-index-list {
                background-color: $base-color;
                padding: 1em 1.5em;
                ul {
                    margin-top: 0.5em;
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 1.4rem;
                    li {
                        width: 33%;
                    }
                }
            }
        }
    };
}

.product-variation-wrap {
    @include mq-sp {
        .product-variation {
            margin-bottom: 5%;
            padding: 1.5em;
            border: solid 1px $border-gy;
            background-color: #fff;
            figure {
                margin-bottom: 1em;
                text-align: center;
            }
        }
    };
    @include mq-pc {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .product-variation {
            width: calc((100% - 40px) / 3);
            margin-bottom: 20px;
            padding: 1.5em;
            border: solid 1px $border-gy;
            background-color: #fff;
            figure {
                margin-bottom: 1em;
                text-align: center;
                img {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    };
}

.tbl-product {
    background-color: #fff;
    th, td {
        border: solid 1px $border-gy;
    }
    th {
        white-space: nowrap;
        font-weight: bold;
        text-align: center;
        background-color: #f6f6f6;
    }
    @include mq-sp {
        th,td {
            padding: 0.75em;
        }
        &.tbl-works {
            white-space: nowrap;
            td {
                text-align: center;
            }
        }
    };
    @include mq-pc {
        th,td {
            padding: 1em;
        }
        &.tbl-works {
            td {
                text-align: center;
            }
        }
    };
}

.attention {
    font-weight: bold;
    @include mq-sp {
        margin-top: 3em;
    };
    @include mq-pc {
        margin-top: 4em;
        font-size: 1.7rem;
        text-align: center;
    };
}


//------------------------------------------------------
// solution
//------------------------------------------------------
.tbl-solution {
    background-color: #fff;
    line-height: 1.6;
    th, td {
        padding: 0.5em;
        border: solid 1px $border-gy;
        vertical-align: middle;
        text-align: center;
    }
    th {
        text-align: center;
        font-weight: bold;
    }
    thead {
        th {
            background-color: $border-gy;
            border-bottom: solid 1px #fff;
            &:not(:last-of-type) {
               border-right: solid 1px #fff;
            }
        }
    }
    tbody {
        th {
        width: 10%;
        }
        td {
        width: 6.5%;
        &:first-of-type {
            width: 15%;
            text-align: left;
            }
        }
    }
    .tbl-valvegate {
        th {
            background-color: #E0E8F1;
        }
    }
    .tbl-spear {
        th {
            background-color: #F1EFD2;
        }
    }
    @include mq-sp {
        width: 260%;
        font-size: $sp-text-SS;
    };
    @include mq-pc {
        width: 100%;
        font-size: 1.1rem;
    };
}
.solution-product {
    @include mq-sp {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 0 3em 0;
        list-style: none;
        li {
            width: calc((100% - 5%) / 2);
            margin-bottom: 5% !important;
            padding: 1em;
            border: solid 1px $border-gy;
            background-color: #fff;
            line-height: 1.6;
            text-align: center;
            figure {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 140px;
            }
            span {
                display: block;
            }
        }
    };
    @include mq-pc {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 3em 0;
        list-style: none;
        li {
            width: calc((100% - 40px) / 3);
            margin-bottom: 20px !important;
            padding: 1em 0;
            border: solid 1px $border-gy;
            background-color: #fff;
            line-height: 1.6;
            text-align: center;
            &:not(:nth-child(3n)) {
                margin-right: 19px;
            }
            figure {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 140px;
            }
            span {
                display: block;
            }
        }
    };
}
.btn-solution {
    list-style: none;
    margin: 0;
    @include mq-sp {
        li {
            a {
                @include button-more-border;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 60px;
                border-radius: 30px;
                line-height: 1.4;
                color: $main-color;
                font-size: $sp-text-S;
                text-decoration: none;
                border: solid 3px $border-gy;
                &::after {
                    font-size: 4.6875vw;
                    right: 0.75em;
                }
            }
        }
    };
    @include mq-pc {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        li {
            width: calc((100% - 20px) / 2);
            a {
                @include button-more-border;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 60px;
                border-radius: 30px;
                line-height: 1.4;
                color: $main-color;
                font-size: 1.4rem;
                text-decoration: none;
                border: solid 3px $border-gy;
                &::after {
                    font-size: 1.5rem;
                }
                &:hover {
                    @include hover-action;
                }
            }
        }
    };
}
.list-molding {
    @include mq-sp {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 0 4em 0;
        list-style: none;
        font-weight: bold;
        li {
            width: calc(100% / 4 );
            font-size: $sp-text-SS;
            text-align: center;
            a {
                display: block;
                text-align: center;
                text-decoration: none;
                .icomoon {
                    display: block;
                    width: 18vw;
                    height: 18vw;
                    margin: 0 auto 3px auto;
                    line-height: 18vw;
                    border-radius: 50% 50%;
                    background-color: $main-color;
                    font-size: 7.5vw;
                    color: #fff;
                }
            }
        }
    };
    @include mq-pc {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 0 4em 0;
        list-style: none;
        font-weight: bold;
        li {
            width: calc(100% / 7 );
            font-size: 1.4rem;
            text-align: center;
            a {
                display: block;
                text-align: center;
                text-decoration: none;
                .icomoon {
                    display: block;
                    width: 80px;
                    height: 80px;
                    margin: 0 auto 5px auto;
                    line-height: 80px;
                    border-radius: 50% 50%;
                    background-color: $main-color;
                    font-size: 3.2rem;
                    color: #fff;
                }
            }
        }
    };
}

.solution-faq {
	@include mq-sp {
		dt {
			padding-left: 1.5em;
			line-height: 2.0;
			font-weight: bold;
			text-indent: -1.6em;
				&:not(:first-of-type) {
					margin-top: 2em;
					padding-top: 1.5em;
					border-top: solid 1px $border-gy;
				}
				&::before {
					content: "Q.";
					margin-right: 0.3em;
					font-weight: 600;
					font-size: $sp-text-XL;
					font-family: $font-os;
					color: $accent-color-bu;
				}
			}
		dd {
			margin: 1em 0 0 0.5em;
			padding-left: 3em;
			border-left: solid 1px $accent-color-bu;
			text-indent: -1.6em;
			line-height: 2.0;
			&::before {
				content: "A.";
				margin-right: 0.3em;
				font-weight: 600;
				font-size: $sp-text-XL;
				font-family: $font-os;
				color: $accent-color-rd;
			}
		}
	};
	@include mq-pc {
		dt {
			padding-left: 35px;
			line-height: 2.0;
			font-weight: bold;
			text-indent: -34px;
				&:not(:first-of-type) {
					margin-top: 2em;
					padding-top: 1.5em;
					border-top: solid 1px $border-gy;
				}
				&::before {
					content: "Q.";
					margin-right: 8px;
					font-weight: 600;
					font-size: 2.4rem;
					font-family: $font-os;
					color: $accent-color-bu;
				}
			}
		dd {
			margin: 0.5em 0 0 10px;
			padding-left: 56px;
			border-left: solid 1px $accent-color-bu;
			text-indent: -31px;
			line-height: 2.0;
			&::before {
				content: "A.";
				margin-right: 8px;
				font-weight: 600;
				font-size: 2.4rem;
				font-family: $font-os;
				color: $accent-color-rd;
			}
		}
	};
}



//------------------------------------------------------
// contact
//------------------------------------------------------
@include mq-sp {
    .contact-wrap {
        margin-top: 3em;
        padding: 2em 0 1.5em 0;
        border: solid 3px $border-gy;
        background-color: #fff;
        text-align: center;
        h3 {
        }
        address {
            font-size: 6.875vw;
            font-weight: bold;
            a {
                color: $accent-color-bu;
                text-decoration: none;
            }
            .icomoon {
                margin-right: 5px;
                font-size: 6.25vw;
                color: $accent-color-bu;
            }
        }
    }
    .form-contact {
        width: 100%;
        margin: 0 auto;
        padding: 5% 5% 2em 5%;
        border: solid 3px $border-gy;
        background-color: #fff;

        p{
          span{
            &.must{
              color: $accent-color-rd;
              font-weight: bold;
            }
          }
        }

        ul {
            margin-left: 0;
            list-style: none;
        }
        table th, table td {
            display: block;
            vertical-align: middle;
        }
        th {
            padding: 1.5em 0 1em 0;
            font-weight: bold;
            span {
                display: inline-block;
                margin-right: 0.75em;
                padding: 0 0.5em;
                border-radius: 3px;
                color: #fff;
                font-weight: normal;
                font-size: $sp-text-S;
                &.must {
                    color: $accent-color-rd;
                }
                &.optional {
                background-color: #999;
                }
            }
        }
        td {
            padding-bottom: 1.5em;
            border-bottom: solid 1px $border-gy;
        }
        label {
            margin-left: 10px;
        }
        input, select, textarea {
            border: solid 2px $border-gy;
            padding: 0.5em;
            border-radius: 5px;
            font-size: $sp-text-L !important;
            font-family: $font-yugo;
            color: #333;
        }
        input.input-S, input.input-M, input.input-L {
            width: 100%;
        }
        textarea {
            width: 100%;
            height: 10em;
        }
    }
    .form-privacy {
        margin-top: 2.5em;
        padding: 1.5em;
        background-color: #f6f6f6;
        h4 {
            margin-bottom: 1em;
            font-size: $sp-text-M;
        }
        dl {
            font-size: $sp-text-S;
            dt {
                font-weight: bold;
                &:not(:first-of-type) {
                    margin-top: 1em;
                }
            }
        }
        .check-policy {
            margin-top: 1.5em;
            padding: 1em 0;
            text-align: center;
            font-size: $sp-text-M;
            font-weight: bold;
            background-color: $border-gy;
        }
    }
    .btn-submit {
        margin-top: 2em;
        text-align: center;
        button {
            @include button-more;
            border: none;
            width: 70vw;
            height: 4em;
            line-height: 4em;
            border-radius: 2em;
            font-family: $font-yugo;
            font-size: 3.75vw;
            &::after {
                font-size: 4.6875vw;
            }
        }
    }
};
@include mq-pc {
    .contact-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 160px;
        margin-top: 4em;
        border: solid 3px $border-gy;
        background-color: #fff;
        h3 {
            margin-right: 2em;
            font-size: 1.8rem;
        }
        address {
            font-size: 3.4rem;
            font-weight: bold;
            color: $accent-color-bu;
            .icomoon {
                margin-right: 5px;
                font-size: 3.0rem;
            }
        }
    }
    .form-contact {
        width: 100%;
        margin: 0 auto;
        padding: 40px 80px 80px 80px;
        border: solid 3px $border-gy;
        background-color: #fff;

        p{
          span{
            &.must{
              color: $accent-color-rd;
              font-weight: bold;
            }
          }
        }
        ul {
            margin-left: 0;
            list-style: none;
        }
        table th, table td {
            padding: 20px;
            border-bottom: solid 1px $border-gy;
            vertical-align: middle;
        }
        th {
            width: 33%;
            font-weight: bold;
            span {
                display: inline-block;
                margin-right: 10px;
                padding: 0 8px;
                border-radius: 3px;
                color: #fff;
                font-weight: normal;
                font-size: 1.5rem;
                &.must {
                    color: $accent-color-rd;
                    font-weight: bold;
                }
                &.optional {
                background-color: #999;
                }
            }
        }
        label {
            margin-left: 10px;
        }
        input, select, textarea {
            border: solid 2px $border-gy;
            padding: 0.5em;
            border-radius: 5px;
            font-size: 16px !important;
            font-family: $font-yugo;
            color: #333;
        }
        input.input-S {
            width: 10em;
        }
        input.input-M {
            width: 20em;
        }
        input.input-L {
            width: 30em;
        }
        textarea {
            width: 100%;
            height: 15em;
        }
    }
    .form-privacy {
        margin-top: 80px;
        padding: 40px;
        background-color: #f6f6f6;
        h4 {
            margin-bottom: 1em;
            font-size: 1.6rem;
        }
        dl {
            font-size: 1.4rem;
            dt {
                font-weight: bold;
                &:not(:first-of-type) {
                    margin-top: 1em;
                }
            }
        }
        .check-policy {
            margin-top: 25px;
            padding: 1em 0;
            text-align: center;
            font-size: 1.6rem;
            font-weight: bold;
            background-color: $border-gy;
        }
    }
    .btn-submit {
        margin-top: 40px;
        text-align: center;
        button {
            @include button-more;
            border: none;
            width: 250px;
            height: 56px;
            line-height: 56px;
            border-radius: 28px;
            font-family: $font-yugo;
            font-size: 1.6rem;
            &::after {
                font-size: 1.7rem;
            }
            &:hover {
                @include hover-action;
                cursor: pointer;
            }
        }
    }
};



//------------------------------------------------------
// download
//------------------------------------------------------
.btn-more.btn-download,
.btn-more-border.btn-download {
    margin: 1em 0 0 0;
    @include mq-pc {
        a {
            width: 300px;
        }
    };
}
.flex-container .download-box {
    padding: 3em 0;
}
.download-sample {
    border: solid 1px $border-gy;
    background-color: #fff;
    @include mq-sp {
        padding: 2em 2em 1em 2em;
        img {
            margin-top: 1em;
        }
    };
    @include mq-pc {
        padding: 3em;
        .text-box {
                width: 450px;
        }
        img {
            width: 400px;
            height: auto;
        }
    };
}



//------------------------------------------------------
// recruit
//------------------------------------------------------
.tbl-recruit {
    width: 100%;
    background-color: #fff;
    border: solid 1px $border-gy;
    th {
        font-weight: bold;
    }
    @include mq-sp {
        tr:not(:last-of-type) {
            border-bottom: solid 1px $border-gy;
        }
        tr:nth-of-type(odd) {
            background-color: #f6f6f6;
        }
        th,td {
            display: block;
        }
        th {
            padding: 0.75em 0.75em 0 0.75em;
        }
        td {
            padding: 0 0.75em 0.75em 0.75em;
        }
    };
    @include mq-pc {
        th,td {
            padding: 1em;
            border: solid 1px $border-gy;
        }
        th {
            width: 25%;
            background-color: #f6f6f6;
            text-align: center;
        }
    };
}

.recruit-pict-wrap {
    @include mq-sp {
        margin-top: 5em;
    };
    @include mq-pc {
        display: flex;
        margin-top: 8em;
    };
}



//------------------------------------------------------
// sitemap
//------------------------------------------------------
@include mq-pc {
    .sitemap-nav-wrap {
        display: flex;
        justify-content: space-between;
        .sitemap-nav {
            width: calc((100% - 40px) / 3);
        }
    }
};

.sitemap-nav {
    & > ul {
        margin-left: 0;
    }
    a {
        text-decoration: none;
    }
    h3 a {
        display: block;
        margin-bottom: 1.5em;
        &::before {
            @include bullet-arrow;
            color: $accent-color-bu;
        }
    }
    .sitemap-nav-sub {
        margin: -1em 0 3em 1.25em;
        line-height: 1.5;
        @include mq-sp {
            font-size: $sp-text-S;
        };
        @include mq-pc {
            font-size: 1.3rem;
        };
        & > li {
            font-weight: bold;
        }
        a::before {
            @include bullet-border;
        }
        ul {
            margin: 1em 0 0 1.5em;
            font-weight: normal;
            a::before {
                content: '';
            }
        }
    }
}



//------------------------------------------------------
// ir
//------------------------------------------------------
.ir-index-wrap {
    margin-top: 4em;
    .flex-half.ir-index {
        border-top: solid 3px $main-color;
    }
}
.flex-half.ir-chart {
    padding: 1.5em !important;
}
.ir-index-nav {
    padding: 2em;
    border: solid 1px $border-gy;
    border-top: solid 3px $main-color;
    background-color: #fff;
    li {
        margin-bottom: 0;
    }
    @include mq-pc {
        ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li {
                width: 50%;
            }
        }
    };
}

.news {
    dt {
        font-weight: bold;
        .category {
            display: block;
            width: 9.25em;
            height: 1.75em;
            line-height: 1.75em;
            text-align: center;
            color: #fff;
            overflow: hidden;
        }
    }
    @include mq-sp {
        dt .category {
            font-size: $sp-text-S;
        }
        dd {
            margin-bottom: 1.5em;
            padding: 0.5em 0 1.5em 0;
            border-bottom: 1px solid $border-gy;
            img {
                max-width: auto;
                width: auto;
                height: auto;
            }
        }
    };
    @include mq-pc {
        dt {
            float: left;
            width: 11em;
            .category {
                margin-top: -3px;
                font-size: 1.3rem;
            }
        }
        dd {
            margin-bottom: 1.5em;
            padding: 0 0 2em 11em;
            border-bottom: 1px solid $border-gy;
        }
    };
}

.ir-chart {
    text-align: center;
    .unit {
        margin-top: 1em;
    }
    .xj_chart_table {
        width: 100%;
        margin-top: 1.5em;
        td:first-child {
            display: none;
        }
        td {
            padding: 0.25em 0;
            border: solid 1px $border-gy;
            text-align: center;
            &.xj_chart_header {
                background-color: #f6f6f6;
            }
        }
    }
    @include mq-sp {
        .unit, .xj_chart_table {
            font-size: $sp-text-SS;
        }
    };
    @include mq-pc {
        .unit, .xj_chart_table {
            font-size: 1.2rem;
        }
    };
}

#xj-mainlist {
    h3 {
        margin-bottom: 1em;
        font-size: 2.0rem;
        &::before {
            @include bullet-border;
        }
    }
}

#xj-select-year_s {
    margin-bottom: 3em;
    border: solid 2px $border-gy;
    padding: 0.5em;
    border-radius: 5px;
    font-family: $font-yugo;
    color: #333;
    @include mq-sp {
        font-size: $sp-text-M;
    };
    @include mq-pc {
        font-size: 1.6rem;
    };
}

#xj-select-category {
    #type {
        display: flex;
        width: 100%;
        margin: -1em 0 3em 0;
        list-style: none;
        @include mq-sp {
            flex-wrap: wrap;
            li {
                width: calc(100% / 2);
                margin-bottom: 0;
                border-bottom: solid 1px #fff;
                &:nth-child(odd) {
                    border-right: solid 1px #fff;
                }
                &.active a {
                    background-color: $accent-color-bu;
                }
                a {
                    display: block;
                    padding: 0.5em 0;
                    background-color: $main-color;
                    text-align: center;
                    text-decoration: none;
                    color: #fff;
                }
            }
        };
        @include mq-pc {
            li {
                width: calc(100% / 6);
                margin-bottom: 0;
                &:not(:last-child) {
                    border-right: solid 1px #fff;
                }
                &.active a {
                    background-color: $accent-color-bu;
                }
                a {
                    display: block;
                    padding: 0.5em 0;
                    background-color: $main-color;
                    text-align: center;
                    text-decoration: none;
                    color: #fff;
                    &:hover {
                        background-color: $accent-color-bu;
                        transition: 0.3s;
                    }
                }
            }
        };
    }
}

.btn-more-ir {
    @include mq-sp {
        margin-top: 1.5em;
        text-align: right;
        a {
            @include button-more;
            width: 40vw;
            height: 3em;
            line-height: 3em;
            border-radius: 1.5em;
            &::after {
                font-size: 4.6875vw;
                right: 0.75em;
            }
        }
    };
    @include mq-pc {
        margin-top: 1.5em;
        text-align: right;
        a {
            @include button-more;
            width: 20%;
            height: 38px;
            line-height: 38px;
            border-radius: 19px;
            font-size: 1.4rem;
            &::after {
                font-size: 1.5rem;
            }
            &:hover {
                @include hover-action;
            }
        }
    };
}

dl.news dd span {
    margin-left: 0.5em;
    img {
        display: none;
        visibility: hidden;
    }
    &::before {
        content: url(../images/ir/icon_pdf.png);
        margin-right: 0.5em;
        vertical-align: middle;
    }
}



//------------------------------------------------------
// flow
//------------------------------------------------------
.flow-box-contaner {
	@include mq-sp {
		.flow-box {
			position: relative;
			margin-bottom: 5em;
			&::before {
				position: absolute;
				top: 0.7em;
				left: 0;
				display: block;
				width: 2.4em;
				height: 2.4em;
				margin-top: -20px;
				line-height: 2.4em;
				border: solid 3px $main-color;
				border-radius: 50% 50%;
				background-color: #fff;
				text-align: center;
				font-size: $sp-text-LL;
				font-weight: 700;
				font-family: $font-os;
				color: $main-color;
			}
			&.flow-1::before {
				content: "1";
			}
			&.flow-2::before {
				content: "2";
			}
			&.flow-3::before {
				content: "3";
			}
			&.flow-4::before {
				content: "4";
			}
			&.flow-5::before {
				content: "5";
			}
			&.flow-6::before {
				content: "6";
			}
			&.flow-7::before {
				content: "7";
			}
			&.flow-8::before {
				content: "8";
			}
			h3 {
				position: relative;
				height: 2.6em;
				margin: 0 0 1.5em 5em;
				padding: 0 1em;
				line-height: 2.6em;
				background-color: $main-color;
				color: #fff;
			}
			h3::before {
				content: '';
				position: absolute;
				display: block;
				width: 0;
				height: 0;
				left: -1em;
				top: 0;
				border-left: none;
				border-right: 1em solid $main-color;
				border-top: 1.3em solid transparent;
				border-bottom: 1.3em solid transparent;
			}
			p {
				margin-bottom: 1em;
				line-height: 1.8;
			}
		}
	};
	@include mq-pc {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-content: flex-start;
		width: 940px;
		margin: 0 auto;
		padding-top: 100px;
		background: url(../images/flow/bcg_flow.png) no-repeat center top;
		.flow-box {
			position: relative;
			width: 400px;
			&:nth-of-type(odd) {
				margin-top: -100px;
			}
			&:nth-of-type(even) {
				margin-top: 200px;
			}
			&:nth-of-type(odd)::after {
				position: absolute;
				right: -108px;
				top: -20px;
				display: block;
				width: 70px;
				height: 70px;
				line-height: 70px;
				border: solid 4px $main-color;
				border-radius: 50% 50%;
				background-color: #fff;
				text-align: center;
				font-size: 2.6rem;
				font-weight: 700;
				font-family: $font-os;
				color: $main-color;
			}
			&:nth-of-type(even)::before {
				position: absolute;
				left: -108px;
				top: -20px;
				display: block;
				width: 70px;
				height: 70px;
				line-height: 70px;
				border: solid 4px $main-color;
				border-radius: 50% 50%;
				background-color: #fff;
				text-align: center;
				font-size: 2.6rem;
				font-weight: 700;
				font-family: $font-os;
				color: $main-color;
			}
			&.flow-1::after {
				content: "1";
			}
			&.flow-2::before {
				content: "2";
			}
			&.flow-3::after {
				content: "3";
			}
			&.flow-4::before {
				content: "4";
			}
			&.flow-5::after {
				content: "5";
			}
			&.flow-6::before {
				content: "6";
			}
			&.flow-7::after {
				content: "7";
			}
			&.flow-8::before {
				content: "8";
			}
			h3 {
				position: relative;
				height: 40px;
				margin-bottom: 1em;
				padding: 0 1em;
				line-height: 40px;
				background-color: $main-color;
				color: #fff;
			}
			h3::before {
				content: '';
				position: absolute;
				display: block;
				width: 0;
				height: 0;
				right: -15px;
				top: 0;
				border-left: 15px solid $main-color;
				border-top: 20px solid transparent;
				border-bottom: 20px solid transparent;
			}
			&:nth-of-type(even) {
				h3::before {
					content: '';
					position: absolute;
					display: block;
					width: 0;
					height: 0;
					left: -15px;
					top: 0;
					border-left: none;
					border-right: 15px solid $main-color;
					border-top: 20px solid transparent;
					border-bottom: 20px solid transparent;
				}
			}
			p {
				margin-bottom: 1em;
				line-height: 1.8;
			}
		}
	};
}

.tbl-support {
    line-height: 1.6;
    th, td {
        padding: 1em 0.5em;
        border-left: solid 1px $border-gy;
        vertical-align: middle;
        text-align: center;
        @include mq-sp {
            font-size: $sp-text-S;
        };
        @include mq-pc {
            font-size: 1.3rem;
        };
    }
    tbody th {
        font-weight: normal;
    }
}




.category.icon_discro{background:#008f93;}
.category.icon_result{background:#815490;}
.category.icon_legal{background:#dc6000;}
.category.icon_ir{background:#c40026;}
.category.icon_info{background:#008740;}

.kv-tanto{
  width: 380px;
  padding: 15px;
  background: rgba(0,0,0,0.5);
  display: flex;
  position: absolute;
  top:25px;
  right: 25px;
  gap: 0 18px;
  @include mq-sp {
    padding: 1rem;
    position: relative;
    width: 320px;
    margin: 3rem auto 2rem;
    top:auto;
    right: auto;
    flex-direction: column;
    background: rgba(0,0,0,1);
  }
  figure{
    width: 30%;
    @include mq-sp {
      width: 100%;
    }
    img{
      width: 100%;
    }
  }
  .__ele_txt{
    flex: 1;

    @include mq-sp {
      margin-top: 1rem;
    }
    p{
      line-height: 1.6;
      font-size: 1.3rem;
      color: #fff;
      margin-top: 15px;
      &:first-child{
        margin-top: 0;
      }
      a{
        color: #fff;
      }
    }
  }

}

.kv-tanto_who{
  width: 400px;

  display: flex;
  position: relative;
  z-index: 1;
  gap: 0 18px;
  border: 1px solid #ccc;
  margin-top: 3rem;
  padding: 1rem;
  @include mq-sp {

    position: relative;
    width: 320px;
    margin: 3rem auto 2rem;
    top:auto;
    right: auto;
    flex-direction: column;
  }
  figure{
    width: 30%;
    display: inline-block;
    @include mq-sp {
      width: 100%;
    }
    img{
      width: 100%;
    }
  }
  .__ele_txt{
    width: 70%;

    @include mq-sp {
      width: 100%;
      margin-top: 1rem;
    }
    p{
      line-height: 1.6;
      font-size: 1.3rem;
      margin-top: 15px;
      &:first-child{
        margin-top: 0;
      }

    }
  }

}

.--bnr{
  width: 400px;
  margin-top: 15px;

  @include mq-sp {
    width: 100%;
    margin-top: 1rem;
  }
  a{
    display: block;
    text-align: center;

    img{
      width: 30%;
    }
  }
}
