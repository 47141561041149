.german{
  .footer-mypage {
      @include mq-sp {
          a,span{
              i {
                  @include center-v;
                  left: 16vw;
              }
          }
          
      };
      @include mq-pc {
          background-color: $main-color;
          line-height: 80px;
          a ,span{
              i {
                  left: 60px;
              }
          }

      };
  }
}


.english,
.german,
.thailand{
  .list-facility{
    display: block;
    li{
      width: 100%;
    }
  }
}

.japanese {
  #home-news::before {
    @include mq-sp {
      height: 57em;
    };
  }
}

@include mq-sp {
  .english,
  .german {
    .tbl-common.tbl-company {
      width: 130%;
    }
  }
  .thailand,
  .korean,
  .english,
  .german {
    .tbl-common.tbl-company {
      dl {
        line-height: 1.6;
        dt {
          float: none;
          width: 100%;
          font-weight: bold;
        }
        dd {
          margin-left: 0;
          &:not(:last-of-type) {
            margin-bottom: 1em;
          }
        }
      }
    }
  }
};
@include mq-pc {
  .english,
  .thailand,
  .korean {
    .tbl-common.tbl-company {
      dt {
        float: left;
        width: 11em;
      }
      dd {
        margin-left: 11em;
      }
    }
  }
  .german {
    .tbl-common.tbl-company {
      dt {
        float: left;
        width: 20em;
      }
      dd {
        margin-left: 20em;
      }
    }
  }
};
