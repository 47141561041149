
#proot{
  z-index: 999;
  position: fixed;
  top:0;
  left: 0;

  h1{
    font-size: 200%;
    color: #fff;
  }
  p{
    color: #fff;
  }
  a{
    color: #fff;
    text-decoration:underline;
  }
  .search-close{
    color: #fff;
      font-size: 300%;
      text-align: right;
      position: fixed;
      top:10px;
      right:10px;
  }
  .search-content{
    height: 100vh;
    width: 100%;
    padding: 65px;
    overflow-y: auto;
    background: rgba(00,00,00,0.8);
  }

  .search-results{
    div{
      margin-top: 25px;
    }
  }

  ul{
    margin-top: 35px;
    display: flex;
    li{
      margin-right: 12px;
      color: #fff;
      list-style: none;
      a{

      }
    }
  }
}


.search-field{
  display: none !important;
  visibility: hidden !important;
  background: #002665;
  padding: 25px 0;
  transition: 0.3s;
  transform: scaleY(0);
  transform-origin: 50% 0;
  .inner{
    width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content:flex-end;
    align-items: center;
    position: relative;
  }
  .btn{
    position: absolute;
    top:0;
    right: 0;

    i{
      display: block;
      top:8px;
      left:8px;
      position: absolute;
      color: #fff;
      display: block;
      font-size: 2.3rem;
    }
  }

  input{
    box-sizing: border-box;

  }
  input[type="text"]{
    width: 100%;
    font-size: 100%;
    padding: 8px 25px;
    height: 40px;
    border-radius: 30px;
    border: 2px solid rgba(255,255,255,0.5);
    background: none;
    color: #fff;
  }

  input[type="button"]{
    //border:2px solid rgba(255,255,255,0.5);
    border:none;
    border-radius: none;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    background: none;
  }
}

.search-field-open{
  display: block !important;
  visibility: visible !important;
  transform: scaleY(1);
}

.search-open{
  i{
    &:before{
      content: "×"
    }
  }
}
