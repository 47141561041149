.footer-utility-bu {
    @include mq-pc {
        background-color: $main-color;
        ul {
            display: flex;
            justify-content: space-between;
            width: 1040px;
            margin: 0 auto;
            padding: 40px 0;
            li {
                width: calc(100% / 3);
                text-align: center;
                &:not(:first-child) {
                    border-left: solid 1px $highlight-color;
                }
                a {
                    display: block;
                    color: #fff;
                    font-size: 1.6rem;
                    font-weight: bold;
                    text-decoration: none;
                    &:hover {
                        @include hover-action;
                    }
                    i {
                        display: block;
                        margin-bottom: 20px;
                        font-size: 4rem;
                        color: rgba(255, 255, 255, 0.3);
                    }
                    span {
                        display: block;
                        font-family: $font-os;
                        font-weight: 600;
                        font-size: 1.1rem;
                    }
                }
            }
        }
    };
}

footer {
    nav a {
        text-decoration: none !important;
    }
    @include mq-pc {
        width: 1040px;
        margin: 100px auto 50px auto;
        .footer-nav-wrap {
            display: flex;
            margin-bottom: 80px;
            nav a:hover {
                @include hover-action;
            }
        }
    }
}

.footer-utility {
    @include mq-sp {
        width: 100%;
        padding: 5%;
        background-color: $main-color;
        li {
            width: 100%;
            text-align: center;
            font-weight: bold;
            height: 5em;
            &:not(:last-child) {
                margin-bottom: 5%;
            }
            a {
            color: #fff;
            }
        }
    };
    @include mq-pc {
        width: calc(1040px / 3);
        li {
            width: 100%;
            text-align: center;
            font-weight: bold;
            height: 80px;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    };
}
.footer-document {
    a {
        display: inline-block;
        width: 50%;
        line-height: 1.4;
    }
    @include mq-sp {
        border: solid 3px $highlight-color;
        h2 {
            padding-top: 0.5em;
            font-size: $sp-text-SS;
            color: #fff;
        }
        a:last-of-type {
            border-left: solid 1px $highlight-color;
        }
    };
    @include mq-pc {
        border: solid 3px $main-color;
        h2 {
            padding-top: 10px;
            font-size: 1.2rem;
        }
        a {
            display: inline-block;
            width: 50%;
            line-height: 1.4;
            &:last-of-type {
                border-left: solid 1px #ccc;
            }
        }
    };
}
.footer-contact {
    a {
        display: block;
        width: 100%;
        height: 100%;
    }
    @include mq-sp {
        border: solid 3px $highlight-color;
        line-height: 5em;
    };
    @include mq-pc {
        border: solid 3px $main-color;
        line-height: 80px;
    };
}
.footer-mypage {
    @include mq-sp {
        background-color: $lowlight-color;
        line-height: 5em;
        a,span{
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            color: #fff;
            i {
                @include center-v;
                left: 20vw;
                padding: 0.5em;
                background-color: #fff;
                border-radius: 50% 50%;
                color: $accent-color-bu;
                font-weight: bold;
                font-size: $sp-text-XL;
            }
        }
    };
    @include mq-pc {
        background-color: $main-color;
        line-height: 80px;
        a ,span{
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            color: #fff;
            i {
                @include center-v;
                background-color: #fff;
                border-radius: 50% 50%;
                color: $accent-color-bu;
                font-weight: bold;
                font-size: 2.0rem;
                left: 80px;
                padding: 8px;
            }
        }
        span{
          background: #666;
        }
    };
}

.footer-nav {
    @include mq-sp {
        & ul {
            display: flex;
            flex-wrap: wrap;
            border-bottom: solid 1px #eee;
            li {
                width: 50%;
                &:not(:last-child) {
                     border-bottom: solid 1px #eee;
                }
                a {
                    display: block;
                    padding: 1em 5%;
                    &::before {
                        @include bullet-arrow;
                        color: $accent-color-bu;
                    }
                }
            }
        }
    };
    @include mq-pc {
        display: flex;
        width: 700px;
        padding-left: 40px;
        & > ul {
            width: calc(100% / 3);
            font-size: 1.3rem;
            h2 a {
                display: block;
                margin-bottom: 1.5em;
                &::before {
                    @include bullet-arrow;
                    color: $accent-color-bu;
                }
            }
        }
        .footer-nav-sub {
            margin: -1.5em 0 1.5em 1.5em;
            font-size: 1.2rem;
            a::before {
                @include bullet-border;
            }
            ul {
                margin: 0 0 1.5em 1.5em;
                a::before {
                    content: '';
                }
            }
        }
    };
}

.credits.container {
    @include mq-sp {
        padding: 3em 0;
        line-height: 1.8;
        font-size: $sp-text-SS;
        text-align: center;
        h2 {
            img {
            width: 120px;
            height: auto;
            }
        }
        address {
            margin: 0.5em 0 2em 0;
            .footer-phone {
                font-size: $sp-text-LL;
                a {
                    text-decoration: none;
                }
            }
            .icomoon {
                margin-right: 3px;
                font-size: 4.6875vw;
            }
        }
        .copyright {
            font-family: $font-os;
        }
    };
    @include mq-pc {
        position: relative;
        display: flex;
        line-height: 1.8;
        font-size: 1.3rem;
        h2 {
            margin-right: 30px;
            img {
            width: 140px;
            height: auto;
            }
        }
        address {
            margin-top: 12px;
            .footer-phone {
                font-size: 2.0rem;
            }
            .icomoon {
                margin-right: 5px;
                font-size: 1.7rem;
            }
        }
        .copyright {
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 1.3rem;
            font-family: $font-os;
        }
    };
}

#page-scroll {
    position: fixed;
    z-index: 100;
    @include mq-sp {
        right: 2vw;
        bottom: 0;
        img {
            width: 50px;
            height: auto;
        }
    };
    @include mq-pc {
        right: 20px;
        bottom: 0;
        a:hover {
            @include hover-action;
        }
    };
}
