// media queries ---------------------------------
$type: common phone screen;
$sp:  "only screen and (max-width:750px)";
$tb: "only screen and (min-width:641px) and (max-width:959px)";
$pc:  "only screen and (min-width:751px)";
@mixin mq-pc {
  @media screen and (min-width:751px) {
    @content;
  }
}
@mixin mq-sp {
  @media screen and (min-width:0px) and (max-width: 750px) {
    @content;
  }
}

// container-size -------------------------------------
$sp-container: 90%;
$pc-container: 1040px;


// font-size (SP) -------------------------------------
$sp-text-SS: 3.125vw; //10px
$sp-text-S: 3.4375vw; //11px
$sp-text-M: 4.0625vw; //13px
$sp-text-L: 4.6875vw; //14px
$sp-text-LL: 5vw; //16px
$sp-text-XL: 5.312vw; //17px


// color ----------------------------------------------
$base-color: #EFF3F8; //#E8EEF4
$main-color: #002C75;
$accent-color-bu: #0046BF;
$accent-color-rd: #E6081F;
$highlight-color: rgba(255, 255, 255, 0.15);
$lowlight-color: #000836;
$border-gy: #ddd; //#e5e5e5


@mixin hover-action {
  opacity: 0.7;
  transition: .3s;
}

@mixin bcg-base {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 150%;
  background-color: $base-color;
  -webkit-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: rotate(-6deg);
  transform: rotate(-6deg);
  z-index: -1;
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

@mixin center-v {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin button-more {
  position: relative;
  display: inline-block;
  background-color: $main-color;
  font-family: $font-os;
  font-weight: 600;
  color: #fff;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.05em;
  &::after {
    content: "\e902";
    font-family: icomoon;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
  }
}

@mixin button-more-border {
  position: relative;
  display: inline-block;
  border: solid 3px $main-color;
  background-color: #fff;
  font-family: $font-os;
  font-weight: 600;
  color: $main-color;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.05em;
  &::after {
    content: "\e902";
    font-family: icomoon;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
  }
}

@mixin bullet-arrow {
    content: "\e902";
    font-family: 'icomoon';
    font-weight: bold;
    @include mq-sp {
      padding-right: 0.3em;
    };
    @include mq-pc {
      padding-right: 5px;
    };
}

@mixin bullet-border {
  content: "\e906";
  font-family: 'icomoon';
  font-weight: normal;
  color: $accent-color-bu;
  @include mq-sp {
    padding-right: 0.5em;
  };
  @include mq-pc {
    padding-right: 5px;
  };
}

@mixin heading-center {
  position: relative;
  display: inline-block;
  padding: 0 0.75em;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 1.25em;
    height: 1px;
    background-color: #333;
  }
  &::before {
    left: 100%;
  }
  &::after {
    right: 100%;
  }
}