.design{
  figure{
    line-height: 0;
    position: relative;
    width: 1400px;
    left: 50%;
    margin-left: -700px;
  }
}



.top-main{
  position: relative;
  width: 1400px;
  height: 800px;
  left: 50%;
  margin-left: -700px;

  figure{
    width: 100%;
    height: 800px;
  }
}
