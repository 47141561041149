//===============================================
//
// PC/SP COMMON STYLE
//
//===============================================

.pc-none {
	@include mq-pc {
        display: none !important;
        visibility: hidden !important;
	};
}
.sp-none {
	@include mq-sp {
		display: none !important;
        visibility: hidden !important;
	};
}

img {
	@include mq-sp {
		max-width: 100%;
		width: 100%;
		height: auto;
	};
}

a{
	&.blue{
		&:visited{
			color: #199FDB;
		}

	}
}

// -----------------------------------------------
// font-size
// -----------------------------------------------
html {
	@include mq-sp {
		font-size: 3.75vw;
	};
	@include mq-pc {
		font-size: 62.5%; /* 10pxにリセット */
	};
}
body {
	font-family: 'Roboto', "モトヤLマルベリ3等幅", "MotoyaLMaru", $font-yugo;
	line-height:2.3;
	color:#333;
	@include mq-pc {
		font-size:1.5rem; /* 15px */
	};
}


// -----------------------------------------------
// hypertext module
//------------------------------------------------
a:link {
	color:#333;
	text-decoration: underline;
}
a:visited {
	color:#333;
	text-decoration: underline;
}
a:hover {
	color:#333;
	text-decoration: underline;
}
a:active {
	color:#333;
	text-decoration: underline;
}


// -----------------------------------------------
// box sizing
//------------------------------------------------
* {
	box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	 -o-box-sizing:border-box;
	-ms-box-sizing:border-box;
}


// -----------------------------------------------
// clearfix
//------------------------------------------------
.clearfix::before,
.clearfix::after {
    content:"";
    display:table;
}
.clearfix::after {
    clear:both;
}


// -----------------------------------------------
// margin
//------------------------------------------------
.anchor-link {
    @include mq-sp {
        padding-top: 70px;
        margin-top: -70px;
    };
    @include mq-pc {
        padding-top: 140px;
        margin-top: -140px;
    };
}
main ul, main ol {
    margin-left: 1.5em;
}
.margin-top-S {
    margin-top: 2em;
}
.margin-top-L {
    margin-top: 6em;
}
.margin-btm-S {
    margin-bottom: 2em !important;
}
.margin-btm-L {
    margin-bottom: 6em !important;
}
.main-content section {
    & + section {
        @include mq-sp {
            margin-top: 5em;
        };
        @include mq-pc {
            margin-top: 6em;
        };
    }
    &.section-child {
        margin-top: 3em;
    }
    li {
        margin-bottom: 1em;
    }
    .margin-none li {
        margin-bottom: 0;
    }
}

video{
	background: #333;
}

// -----------------------------------------------
// text
//------------------------------------------------
main p,
main dl,
main ul,
main ol {
	//text-align: justify;
	//text-justify: inter-ideograph;
}
.text-S {
    @include mq-sp {
        font-size: $sp-text-S;
    };
    @include mq-pc {
        font-size: 1.3rem;
    };
}
.text-L {
    @include mq-sp {
        font-size: $sp-text-L;
    };
    @include mq-pc {
        font-size: 2.0rem;
    };
}
.text-LL {
    @include mq-sp {
        font-size: $sp-text-XL;
    };
    @include mq-pc {
        font-size: 2.8rem;
    };
}
.text-caption {
    text-align: center;
}
.text-lead {
    @include mq-sp {
        margin-bottom: 2em;
        font-size: $sp-text-M;
    };
    @include mq-pc {
        margin-bottom: 3em;
        text-align: center;
        font-size: 2.0rem;
    };
}
.caution {
    color: $accent-color-rd;
}


// -----------------------------------------------
// align, float
//------------------------------------------------
.align-C {
	text-align: center;
}
.align-R {
	text-align: right;
}
.float-R {
	@include mq-pc {
        float: right;
        margin-left: 30px;
	};
}
.float-L {
    @include mq-pc {
        float: left;
	};
}
.text-box {
    @include mq-pc {
        width: 380px
	};
}



// -----------------------------------------------
// pict
//------------------------------------------------
.pict-R {
    img {
        margin-bottom: 1em;
    }
    @include mq-sp {
        text-align: center;
        img {
            width: auto;
            max-width: auto;
            height: auto;
        }
	};
	@include mq-pc {
        float: right;
	};
}
.pict-border {
    width: 100%;
    padding: 1em;
    border: solid 1px $border-gy;
    background-color: #fff;
    text-align: center;
}


// -----------------------------------------------
// table
// -----------------------------------------------
.tbl-wrap {
    @include mq-sp {
        overflow: auto;
    };
}
.tbl-common {
    width: 100%;
    border: solid 1px $border-gy;
    background-color: #fff;
    @include mq-sp {
        th, td {
            padding: 1em;
            border-bottom: solid 1px $border-gy;
        }
        th {
            background-color: #f6f6f6;
            font-weight: bold;
        }
    };
    @include mq-pc {
        th, td {
            padding: 1em 1.5em;
            border-bottom: solid 1px $border-gy;
        }
        th {
            background-color: #f6f6f6;
            font-weight: bold;
        }
    };
}


// -----------------------------------------------
// heading
// -----------------------------------------------
.page-header {
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
    color: #fff;
    &#page-news {
        background-image: url(../images/news/cover.jpg);
        @include mq-sp {
            background-position: -32vw center;
        }
    }
    &#page-company {
        background-image: url(../images/company/cover.jpg);
        @include mq-sp {
            background-position: -78vw center;
        }
    }
    &#page-ir {
        background-image: url(../images/ir/cover.jpg);
        @include mq-sp {
            background-position: -100vw center;
        }
    }
    &#page-product {
        background-image: url(../images/product/cover.jpg);
    }
    &#page-solution {
        background-image: url(../images/solution/cover.jpg);
        @include mq-sp {
            background-position: -100vw center;
        }
    }
    &#page-flow {
        background-image: url(../images/flow/cover.jpg);
        @include mq-sp {
            background-position: -20vw center;
        }
    }
    &#page-contact {
        background-image: url(../images/support/cover.jpg);
    }
    &#page-download {
        background-image: url(../images/download/cover.jpg);
        @include mq-sp {
            background-position: -80vw center;
        }
    }
    &#page-recruit {
        background-image: url(../images/recruit/cover.jpg);
    }
    &#page-privacy {
        background-image: url(../images/privacy/cover.jpg);
    }
    &#page-sitemap {
        background-image: url(../images/sitemap/cover.jpg);
    }
    @include mq-sp {
        width: 100%;
        height: 50vw;
        margin-top: 60px;
        .page-header-container {
            position: absolute;
            @include center;
            width: 100%;
            .page-heading-00 {
                font-weight: normal;
                span {
                    position: relative;
                    display: inline-block;
                    padding: 0 0.75em;
                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        width: 1.5em;
                        height: 1px;
                        background-color: #fff;
                    }
                    &::before {
                        left: 100%;
                    }
                    &::after {
                        right: 100%;
                    }
                }
            }
            .page-heading-01 {
                line-height: 1.6;
                font-size: 5.625vw;
                span {
                    display: block;
                    font-size: $sp-text-S;
                }
            }
        }
	};
	@include mq-pc {
        width: 100%;
        height: 300px;
        margin-top: 140px;
        .page-header-container {
            position: absolute;
            @include center;
            width: 100%;
            .page-heading-00 {
                font-size: 2.0rem;
                font-weight: normal;
                span {
                    position: relative;
                    display: inline-block;
                    padding: 0 0.75em;
                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        width: 1.5em;
                        height: 1px;
                        background-color: #fff;
                    }
                    &::before {
                        left: 100%;
                    }
                    &::after {
                        right: 100%;
                    }
                }
            }
            .page-heading-01 {
                line-height: 1.6;
                font-size: 3.6rem;
                span {
                    display: block;
                    font-size: 1.8rem;
                }
            }
        }
	};
}

.heading-00 {
    position: relative;
    line-height: 1.6;
    &::after {
        content: " ";
        position: absolute;
        display: block;
        border-bottom: solid 3px $accent-color-bu;
        bottom: -3px;
        width: 3em;
   }
    @include mq-sp {
        margin-bottom: 1.5em;
        padding-bottom: 0.5em;
        border-bottom: 3px solid #b8b8b8;
        font-size: 5.625vw;
        span {
            font-size: $sp-text-M;
        }
    };
    @include mq-pc {
        margin: -10px 0 40px 0;
        padding-bottom: 20px;
        border-bottom: 3px solid #b8b8b8;
        font-size: 3.0rem;
        span {
            font-size: 1.8rem;
        }
    };
}

.heading-01 {
    margin-bottom: 1em;
    border-left: solid 3px $accent-color-bu;
    line-height: 1.6;
    @include mq-sp {
        padding-left: 0.75em;
        font-size: $sp-text-LL;
    };
    @include mq-pc {
        padding-left: 10px;
        font-size: 2.4rem;
    };
}

.heading-02 {
    @include mq-sp {
        margin-bottom: 1em;
        line-height: 1.6;
        text-align: center;
        font-size: $sp-text-L;
        span {
            @include heading-center;
        }
    };
    @include mq-pc {
        margin-bottom: 1em;
        text-align: center;
        font-size: 2.4rem;
        span {
            @include heading-center;
        }
    };
}

.heading-03 {
    margin-bottom: 1em;
    &::before {
        @include bullet-border;
    }
    @include mq-sp {
        font-size: $sp-text-L;
    };
    @include mq-pc {
        font-size: 2.0rem;
    };
}

.heading-04 {
    @include mq-sp {
        text-align: center;
        span {
            @include heading-center;
        }
    };
    @include mq-pc {
        text-align: center;
        span {
            @include heading-center;
        }
    };
}

.heading-05 span {
    margin-bottom: 0.5em;
    line-height: 1.5;
    @include mq-sp {
        font-size: $sp-text-M;
    };
    @include mq-pc {
        font-size: 1.7rem;
    };
}


// -----------------------------------------------
// btn
// -----------------------------------------------
.btn-more {
    @include mq-sp {
        margin: 2em 0;
        text-align: center;
        a,span {
            @include button-more;
            width: 70vw;
            height: 4em;
            line-height: 4em;
            border-radius: 2em;
            &::after {
                font-size: 4.6875vw;
                right: 0.75em;
            }
        }
				span {
					background: #666;
					&:after{
						display: none;
					}
				}
    };
    @include mq-pc {
        margin-top: 40px;
        text-align: center;
        a,span {
            @include button-more;
            width: 250px;
            height: 56px;
            line-height: 56px;
            border-radius: 28px;
            &::after {
                font-size: 1.7rem;
            }
            &:hover {
                @include hover-action;
            }
        }
				span {
					background: #666;
					&:after{
						display: none;
					}
				}
    };
}

.btn-more-border {
    @include mq-sp {
        margin: 2em 0;
        text-align: center;
        a {
            @include button-more-border;
            width: 70vw;
            height: 4em;
            line-height: 3.6em;
            border-radius: 2em;
            &::after {
                font-size: 4.6875vw;
                right: 0.75em;
            }
        }
    };
    @include mq-pc {
        margin-top: 40px;
        text-align: center;
        a {
            @include button-more-border;
            width: 250px;
            height: 56px;
            line-height: 50px; /* 上下border3px分マイナス */
            border-radius: 28px;
            &::after {
                font-size: 1.7rem;
            }
            &:hover {
                @include hover-action;
            }
        }
    };
}

.btn-more-S {
    @include mq-sp {
        margin-top: 1.5em;
        text-align: center;
        a {
            @include button-more;
            width: 70vw;
            height: 4em;
            line-height: 4em;
            border-radius: 2em;
            &::after {
                font-size: 4.6875vw;
                right: 0.75em;
            }
						&.updating{
							background-color: #ccc;
							&:after{
								display: none;
							}
						}
        }
    };
    @include mq-pc {
        margin-top: 1.5em;
        text-align: center;
        a {
            @include button-more;
            width: 250px;
            height: 46px;
            line-height: 46px;
            border-radius: 23px;
            font-size: 1.4rem;
            &::after {
                font-size: 1.5rem;
            }
            &:hover {
                @include hover-action;
            }

						&.updating{
							background-color: #ccc;
							&:after{
								display: none;
							}
						}
        }
    };
}



// -----------------------------------------------
// flex-container
// -----------------------------------------------
.flex-container {
    @include mq-sp {
        .flex-half {
            margin-bottom: 5%;
            padding: 2em;
            border: solid 1px $border-gy;
            background-color: #fff;
            ul {
                margin-top: 1em;
                li {
                    margin-bottom: 0;
                }
            }
        }
    };
    @include mq-pc {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .flex-half {
            width: calc((100% - 20px) / 2);
            margin-bottom: 20px;
            padding: 2em;
            border: solid 1px $border-gy;
            background-color: #fff;
            ul {
                margin-top: 1em;
                li {
                    margin-bottom: 0;
                }
            }
        }
    };
}
