header nav a {
  text-decoration: none !important;
}

@include mq-sp {
  header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    background-color: #fff;
  }
  .header-container {
    position: relative;
    height: 60px;
    h1 {
      @include center-v;
      left: 2.5%;
      img {
        width: 100px;
        height: auto;
      }
    }
  }
  .header-contact ul {
    @include center-v;
    right: 16vw;
    display: flex;
    li + li {
      margin-left: 1em;
    }
    a {
      position: relative;
      display: block;
      width: 3em;
      height: 3em;
      border-radius: 50% 50%;
      background-color: $main-color;
      .icomoon {
        @include center;
        color: #fff;
        font-size: 5vw;
      }
    }
  }
  .drawer-nav .drawer-dropdown-menu {
    .drawer-dropdown-menu-item {
      display: block;
      position: relative;
      color: #fff;
      &::before {
        @include bullet-arrow;
        color: #fff;
      }
    }
    .sp-global-sub {
      margin-left: 3em;
    }
  }
  .drawer-nav .utility-wrap {
    padding: 2em;
    background-color: $lowlight-color;
    .utility li {
      margin-bottom: 1.5em;
      padding-bottom: 1.5em;
      border-bottom: solid 1px $highlight-color;
      h2 {
        margin-bottom: 0.5em;
      }
      a {
        position: relative;
        display: block;
        width: 100%;
        height: 4em;
        line-height: 4em;
        border-radius: 2em;
        background-color: $highlight-color;
        text-align: center;
        &::after {
          content: "\e902";
          font-family: icomoon;
          font-size: 4.375vw;
          @include center-v;
          right: 0.75em;
        }
        & + a {
          margin-top: 1em;
        }
      }
    }
    .external {
      display: flex;
      justify-content: space-around;
      margin-bottom: 1.5em;
      padding-bottom: 1em;
      border-bottom: solid 1px $highlight-color;
      li {
        width: calc(100% / 3);
      }
      a,span {
        display: block;
        text-align: center;
        .icomoon {
          display: block;
          width: 16vw;
          height: 16vw;
          margin: 0 auto;
          line-height: 16vw;
          border-radius: 50% 50%;
          background-color: $highlight-color;
          font-size: 6.25vw;
        }
      }
    }
    .search-form {
      position: relative;
      width: 100%;
      //margin-bottom: 3em;
      input[type="search"] {
        position: relative;
        width: 100%;
        height: 4em;
        border-radius: 2em;
        outline: 0;
        border: solid 1px $highlight-color;
        background-color: $lowlight-color;
        color: #fff;
        font-size: 16px !important;
      }
      button[type="submit"] {
        @include center-v;
        right: 8px;
        width: 3.6em;
        height: 3.6em;
        border: none;
        border-radius: 50% 50%;
        background-color: $lowlight-color;
        .icon-search {
          @include center;
          font-size: 6.875vw;
          color: #fff;
        }
      }
    }
  }
};

@include mq-pc {
  header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    nav a:hover {
      @include hover-action;
    }
  }
  .header-container {
    position: relative;
    height: 90px;
    background-color: #fff;
    transition: all 0.3s ease;
    &.scrolled {
      height: 70px;
      transition: all 0.3s ease;
      img {
        width: 130px;
        height: auto;
        transition: all 0.3s ease;
      }
    }
    h1 {
      @include center-v;
      left: 30px;
      img {
        width: 160px;
        height: auto;
      }
    }
  }

  .global {
    @include center-v;
    right: 260px;
    z-index: 9000;
    font-size: 1.4rem;
    font-weight: bold;
    .dropmenu {
      & > li {
        display: block;
        position: relative;
        float: left;
        margin: 0 1em;
      }
      .global-sub {
        display: none;
        position: absolute;
        width: 19em;
        background-color: $main-color;
        color: #fff;
        & > li:not(:last-child) {
          border-bottom: solid .5px $highlight-color;
        }
        a {
          display: block;
          position: relative;
          padding: 1em;
          color: #fff;
          &::before {
            @include bullet-arrow;
            color: #fff;
          }
        }
        ul {
          margin: -.5em 0 1em 0;
          font-weight: normal;
          font-size: 1.3rem;
          a {
            display: block;
            padding: .5em 2em;
            &::before {
              font-size: 1.2rem;
              padding: 0 7px 0 10px;
            }
          }
        }
      }
    }
  }
  .external {
    @include center-v;
    right: 30px;
    border-left: solid 1px #ccc;
    font-size: 1.1rem;
    font-weight: bold;
    ul {
      display: flex;

      a,
      span {
        display: block;
        margin-left: 2em;
        text-align: center;
        .icomoon {
          display: block;
          font-size: 2.5rem;
          color: #999;
        }
      }
    }
  }
  .utility {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    background-color: $main-color;
    font-size: 1.3rem;
    color: #fff;
    ul {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 0 1em;
      border-right: solid 1px $highlight-color;
      border-left: solid 1px $highlight-color;
      li {
        margin-left: 1em;
        &:first-child {
          margin-left: 0;
          padding-right: 1em;
          border-right: solid 1px $highlight-color;
        }
        h2 {
          display: inline-block;
          margin-right: 1em;
          font-weight: normal;
        }
        a {
          position: relative;
          display: inline-block;
          height: 32px;
          line-height: 32px;
          border-radius: 16px;
          padding: 0 30px;
          background-color: $highlight-color;
          color: #fff;
          &::after {
            content: "\e902";
            font-family: icomoon;
            font-size: 1.5rem;
            position: absolute;
            top: 50%;
            right: 7px;
            margin-top: -16px;
          }
        }
      }
    }
    .btn-search-form {
      color: #fff;
      text-decoration: none;
      &:hover {
        cursor: pointer;
      }
    }
    .icon-search {
      font-size: 2.3rem;
      display: block;
      width: 60px;
      height: 50px;
      line-height: 50px;
      text-align: center;
    }
  }
};
