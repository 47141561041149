.pp-error{
  p{
    line-height: 1.0;
    padding: 8px 12px;
    background: #CE3131;
    display: inline-block;
    color: #fff;
    border-radius: 3px;
    font-size:85%;
    margin: 10px 8px 0 0;
  }
}
.btn-submit{
  .form-back{
    background: #666;
    &:after{
      right: auto;
      top: 0;
      left: 1rem;
      transform: scale(-1);
    }
  }
}

/*
.button{
    position: relative;
  display: inline-block;
  background-color: #002C75;
  font-family: "Open Sans",sans-serif;
  font-weight: 600;
  color: #fff;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.05em;
  border: none;
  width: 250px;
  height: 56px;
  line-height: 56px;
  border-radius: 28px;
  font-size: 1.6rem;
}
*/
