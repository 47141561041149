//.product-index-wrap{
//  .pro-id{
//    display: block;
//    .product-index-co{
//      display: flex;
//    }
//    .product-index-list{
//      background: #EFF3F8;
//      padding: 25px;
//      ul{
//        margin-left: 25px;
//        margin-top: 12px;
//        display: flex;
//        flex-wrap: wrap;
//        li{
//          width: 33%;
//        }
//      }
//    }
//  }
//}

.sol-photos {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include mq-sp {
    width: 100%;
    figure {
      width: calc((100% - 5%) / 2);
      margin-bottom: 5%;
      img{
        border:solid 1px $border-gy;
      }
    }
  };
  @include mq-pc {
    width: 650px;
    margin: 0 auto;
    figure{
      margin-bottom: 1.5em;
      img{
        border:solid 1px $border-gy;
      }
    }
  };
}

//.seikei{
//  h4{
//   margin-top: 55px;
//    &:first-child{
//      margin-top: 0;
//    }
//  }
//}
