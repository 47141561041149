#wrapper {
  @include mq-sp {
    padding-top: 60px;
    margin-top: -60px;
  };
  @include mq-pc {
    padding-top: 140px;
    margin-top: -140px;
  };
}

.content-wrap {
  @include mq-sp {
    padding: 1em 0 6em 0;
    background: $base-color url(../images/common/bcg_page.png) repeat-y center top;
    background-size: 500vw auto;
  };
  @include mq-pc {
    padding: 1.5em 0 10em 0;
    background: $base-color url(../images/common/bcg_page.png) repeat-y center top;
  };
}

.container {
  @include mq-sp {
    width: 90%;
    margin: 0 auto;
  };
  @include mq-pc {
    width: 1040px;
    margin: 0 auto;
    &.multicolumn {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  };
}

main {
  display: block;
  position: relative;
  @include mq-pc {
    .singlecolumn & {
      width: 100%;
    }
    .multicolumn & {
      width: 720px;
    }
  };
}

@include mq-sp {
  .sidebar {
    margin-top: 5em;
    .local {
      width: 100%;
      border: solid 1px $base-color;
      background-color: #fff;
      h2 {
        padding: 1.5em 0;
        background-color: $main-color;
        line-height: 1.6;
        font-size: $sp-text-L;
        text-align: center;
        color: #fff;
        .en {
          display: block;
          font-family: $font-os;
          font-size: $sp-text-SS;
          font-weight: normal;
        }
      }
      & > ul {
        padding: 1em;
        font-weight: bold;
        a {
          display: block;
          padding: 0.5em 0em;
          text-decoration: none;
          &::before {
            @include bullet-arrow;
            color: $accent-color-bu;
          }
        }
        .local-sub {
          margin-left: 1.4em;
          line-height: 1.6;
          font-weight: normal;
          font-size: $sp-text-S;
        }
        .local-sub-child {
          margin: 0 0 1em 1.8em;
          text-indent: -0.5em;
          font-weight: normal;
          a {
            line-height: 1.8;
            font-size: $sp-text-S;
            &::before {
              color: #999;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
};
@include mq-pc {
  .sidebar .local {
    width: 260px;
    border: solid 1px $base-color;
    background-color: #fff;
    h2 {
      padding: 30px 0;
      background-color: $main-color;
      line-height: 1.6;
      text-align: center;
      color: #fff;
      font-size: 1.7rem;
      .en {
        display: block;
        font-family: $font-os;
        font-size: 1.1rem;
        font-weight: normal;
      }
    }
    & > ul {
      padding: 1em;
      font-weight: bold;
      font-size: 1.4rem;
      a {
        display: block;
        padding: 0.5em 0;
        text-decoration: none;
        &::before {
          @include bullet-arrow;
          color: $accent-color-bu;
          font-size: 1.4rem;
        }
      }
      .local-sub {
        margin-left: 36px;
        line-height: 1.6;
        text-indent: -1.5em;
        font-weight: normal;
        font-size: 1.3rem;
      }
      .local-sub-child {
        margin-bottom: 1em;
        a {
          margin-left: 22px;
          text-indent: -0.5em;
          line-height: 1.8;
          font-size: 1.3rem;
          font-weight: normal;
          &::before {
            color: #999;
            font-weight: normal;
          }
        }
      }
    }
  }
};

.sidebar .local .select-wrap {
  position: relative;
  margin: 2em;
  &::before {
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 1;
    content: "\e900";
    font-family: 'icomoon' !important;
    font-weight: bold;
    line-height: 37px;
    color: $accent-color-bu;
    pointer-events: none;
  }
  select::-ms-expand {
    display: none;
}
  select {

    outline: none;
    text-indent: 0.01px;
    text-overflow: '';
    background: none transparent;
    vertical-align: middle;
    font-size: inherit;
    color: inherit;
    appearance: button;
    appearance: none;
    height: 40px;
    padding-left: 0.5em;
    border: none;
    border-bottom: 1px solid $border-gy;
    width: 100%;
  }
  @include mq-pc {
    &:hover {
      cursor: pointer;
    }
  };
}

.breadcrumb {
  @include mq-sp {
    width: 90%;
    margin: 0 auto 4em auto;
    ul {
      display: flex;
      flex-wrap: wrap;
      line-height: 1.0;
      font-size: $sp-text-SS;
      li {
        &:not(:last-child) {
          margin: 0 0.75em 0.75em 0;
          padding-right: 0.75em;
          border-right: solid 1px #999;
          white-space: nowrap;
        }
        &:first-child a {
          text-decoration: none;
        }
      }
    }
  };
  @include mq-pc {
    width: 1040px;
    margin: 0 auto 90px auto;
    ul {
      display: flex;
      line-height: 1.0;
      font-size: 1.3rem;
      li {
        &:not(:last-child) {
          margin-right: 1em;
          padding-right: 1em;
          border-right: solid 1px #999;
        }
        &:first-child a {
          font-size: 1.4rem;
          text-decoration: none;
        }
      }
    }
  };
}
