.product-index{
  & > div{
    width: 100%;
  }
}

#product-co{
  .pict-border{
    border:none;
    padding: 0;
    width: auto;
    background: none;
  }
  figure{
    padding: 0;
    line-height: 1;
  }
  img{
    line-height: 0;
    border: 1px solid $border-gy;
    background: #fff;

    @include mq-sp {
      max-width: 100%;
      min-width:auto;
      width: auto;
      text-align: center;
    }
  }

  h3{
    margin-top: 25px;
    font-size: 100%;
    line-height: 1.8;
  }
  p{
    margin-top: 35px;
    line-height: 1.9;
    font-size: 100%;
  }
  h3 + p{
    margin-top: 12px;
  }
  ul,ol{
    margin-top: 35px;
    li{
      margin: 8px 0px 0px 0px;
      line-height: 1.8;
      font-size: 100%;

    }
  }
  dl{
    margin-top: 18px;
    dt,dd{
      font-size: 100%;
      line-height: 1.8;
    }
    dt{
      border-top:solid 1px $border-gy;
      margin-top: 18px;
      padding-top: 18px;
      font-weight:bold;
    }
    dd{
      margin: 3px 0px 0px 18px;
    }
  }
  table{

    margin-top: 25px;
    border-collapse: collapse;
    @include mq-sp {
      white-space: nowrap;
    }
    @include mq-pc {
      width: 100%;
    }
    td,th{
      font-size: 90%;
      background: #fff;
      padding: 8px 8px;
      border:solid 1px $border-gy;
    }
    th{
      font-weight: bold;
      background: #f6f6f6;
    }

    td{
      dl{
        dt{
          &:first-child{
            border: none;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
  .product-variation-wrap{
    h3{
      margin-top: 8px;
    }
    figure{
      img{
        border: none;
        padding: 0;
      }
    }
  }
  @include mq-pc {
  .photo-right{
    .photo{
      margin-left:45px;
      float: right;
    }
  }
  .photo-left{
    .photo{
      margin-right:45px;
      float: left;
    }
  }
}
  .product-variation-wrap{
    @include mq-pc {

      justify-content: flex-start;
      .product-variation{
        margin-right: 15px;
      }

      .column-2{
        width: calc((100% - 40px) / 2);
        &:nth-child(2n){
          margin-right: 0px;
        }
      }
      .column-3{
        width: calc((100% - 40px) / 3);

        &:nth-child(3n){
          margin-right: 0px;
        }
      }
      .column-4{
        width: calc((100% - 40px) / 4);

        &:nth-child(4n){
          margin-right: 0px;
        }
      }
    };
  }

}
