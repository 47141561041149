
.acodion-navi{
  position: relative;
  &::after {
    @include center-v;
    right: 0;
    content: "\e908" !important;
    font-family: 'icomoon' !important;
    font-weight: bold !important;
    color: $accent-color-bu;
  }

  & + div {
    height: 0;
    transform: scaleY(0);
    opacity: 0;
    transition: 0.2s;
    transform-origin: 0% 0%;
  }
}

.acodion-open {
  & + div{
    opacity: 1;
    transform: scaleY(1);
  }
  &::after {
    @include center-v;
    right: 0;
    content: "\e906" !important;
    font-family: 'icomoon' !important;
    font-weight: bold !important;
    color: $accent-color-bu;
  }
}

.acodion-navi-sub {
  &::after {
    @include center-v;
    right: -0.5em;
    content: "\e908" !important;
    font-family: 'icomoon' !important;
    font-weight: bold !important;
    color: $accent-color-bu;
  }
}

@include mq-sp {
  .acodion-navi-sub{
    position: relative;
    &::after {
      @include center-v;
      right: 0;
      content: "\e908" !important;
      font-family: 'icomoon' !important;
      font-weight: bold !important;
      color: $accent-color-bu;
    }

    & + div {
      height: 0;
      transform: scaleY(0);
      opacity: 0;
      transition: 0.1s;
      transform-origin: 0% 0%;
    }
  }

  .navi-sub-open {

    &::after {
      @include center-v;
      right: 0;
      content: "\e908" !important;
      font-family: 'icomoon' !important;
      font-weight: bold !important;
      color: $accent-color-bu;
    }

    & + div {
      transform: scaleY(1);
      opacity: 1;
    }
  }


}


@include mq-pc {
  .sidebar{
    .local{
      .acodion-sub-navi{
        position: relative;

        div{
          position: absolute;
          padding-top: 18px;
          transform: scaleY(0);
          transition: 0.2s;
          transform-origin: 0% 0%;

          z-index: 10;
          top:0;
          left: 200px;
          background: rgba(0,4,117,0.8);
          width: 300px;

          ul{
            li{
              a{
                padding: 6px 12px;
                color: #fff;
                &:before{
                  color: #fff;
                }
              }
            }
          }
        }

        &:hover{
          div{
            transform: scaleY(1);
          }
        }
      }
    }
  }
}
